$debug: true;

$nb-namespace: '' !default;
$nb-site-wrap-width: 82.5rem !default;
$nb-base-font-size: 1rem !default;

$nb-spacing-unit: 1rem !default;
$nb-spacing-unit-half: ($nb-spacing-unit / 2) !default;
$nb-spacing-unit-double: ($nb-spacing-unit * 2) !default;

$nb-breakpoints: (
  xs: 480px,
  sm: 720px,
  md: 960px,
  lg: 1200px,
  xl: 1400px
) !default;


$nb-root-sizing: (
  default: 75%,
  xs: 80%,
  sm: 85%,
  md: 90%,
  lg: 100%
) !default;

$nb-list-spacing: (
  md: $nb-spacing-unit
) !default;

$nb-section-spacing: (
  md: $nb-spacing-unit-double
) !default;

$nb-flag-gutter-sizes: (
  md: $nb-spacing-unit
) !default;

$nb-use-grid-zero-font-size: true !default;
$nb-grid-gutter-sizes: (
  sm: $nb-spacing-unit-half,
  md: $nb-spacing-unit,
  lg: $nb-spacing-unit-double
) !default;

$nb-use-width-offsets: true !default;
$nb-use-push-offsets: true !default;
$nb-use-pull-offsets: true !default;
$nb-offset-fractions: (
  '1/1': 1/1,
  '1/2': 1/2,
  '1/3': 1/3,
  '2/3': 2/3,
  '1/4': 1/4,
  '2/4': 2/4,
  '3/4': 3/4,
  '1/5': 1/5,
  '2/5': 2/5,
  '3/5': 3/5,
  '4/5': 4/5,
  '1/6': 1/6,
  '2/6': 2/6,
  '3/6': 3/6,
  '4/6': 4/6,
  '5/6': 5/6,
  '1/8': 1/8,
  '2/8': 2/8,
  '3/8': 3/8,
  '4/8': 4/8,
  '5/8': 5/8,
  '6/8': 6/8,
  '7/8': 7/8,
  '1/10': 1/10,
  '2/10': 2/10,
  '3/10': 3/10,
  '4/10': 4/10,
  '5/10': 5/10,
  '6/10': 6/10,
  '7/10': 7/10,
  '8/10': 8/10,
  '9/10': 9/10,
  '1/12': 1/12,
  '2/12': 2/12,
  '3/12': 3/12,
  '4/12': 4/12,
  '5/12': 5/12,
  '6/12': 6/12,
  '7/12': 7/12,
  '8/12': 8/12,
  '9/12': 9/12,
  '10/12': 10/12,
  '11/12': 11/12
) !default;

$nb-push-sizes: (
  md: $nb-spacing-unit
) !default;

$nb-soft-sizes: (
  md: $nb-spacing-unit
) !default;
