.section--form {

	

}

.form-field {

	margin-bottom: 30px;


	@include respond-to('sm'){

		margin-bottom: 60px;

	}

	&__title {
		font-size: $font-size__h4;

	}

	&__input--text,
	&__input--email,
	&__textarea {

		border: 1px solid $colour__dark-grey;
		padding: 5px 10px;
		font-size: 20px;
		width: 100%;
		box-sizing: border-box;
	}

	.error-message {
		display: none;

		background-color: $colour__error;
		border: 1px solid $colour__error;
		color: $colour__white;
		padding: 5px 10px;
		//float: right;
	}

	&.error .error-message {
		display: block;
	}

	&__button--submit {

		@extend #button;
		text-decoration: underline;

	}

}