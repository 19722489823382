
body.home{

	// Set homepage header content to white for contrast against image+overlay.
	header{
		color: $colour__white;
		
		a{
			color: $colour__white;
		}

		& ~ section:first-of-type{
			padding-top: 0;
		}

	}
}


header{
	color: $colour__dark-grey;
	
	a{
		color: $colour__dark-grey;
	}

	& ~ section:first-of-type{
		padding-top: 30px;
	}

}




/**
 * Logo
 */
.logo{

	text-align: left;

	a{ 
		text-decoration: none;
	}

	.title{
		font-family: $font__logo;

		font-size: $font-size__logo--mobile;
		line-height: $font-size__logo--mobile * 1.05;
		margin-bottom: 0;

		@include respond-to('sm') {

			font-size: $font-size__logo;
			line-height: $font-size__logo * 1.05;

		}

		text-transform: uppercase;
	}

	.tagline{
		font-size: rem( 14px );

		@include respond-to('sm') {

			font-size: 0.875rem;
		
		}
	}

	@include respond-to('md') {
		//text-align: left;
	}

}