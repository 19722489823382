
.banner-home {

	background-image: url('img/home_banner1.jpg');
	
}

.banner {

	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	width: 100%;
 	opacity: 1; 

	height:100%;
	min-height:100%;/*for mozilla*/

	.container{
		padding-top: 100px;

		@include respond-to('sm'){
			padding-top: 200px;
		}
	}

	.content{

		a {		
			color: $colour__white;

		}
	}

	.title{
		
		@extend #site-title;
 		border-color: $colour__white;
	}
}

html>body {
	.banner,
	.overlay {
		height:auto;
	}
}

.banner, 
.image-block, 
.trip {
	color: $colour__white;
	width: 100%;
}

